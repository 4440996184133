<template>
  <div class="main">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  props: {
    // 父辈向子辈传参
  },
  name: "Main",
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  },
};
</script>
<style scoped lang='scss'>
  @import "@/styles/config.scss";
  .main {
    position:absolute;
    top:60px;
    left:0;
    right:0;
    bottom:0;
    overflow:auto;
  }
</style>